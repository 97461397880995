/* styles.css */
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');


body {
    margin: 0;
    padding: 0;
    background-color: white;
    font-family: sans-serif;
    min-height: 100vh; /* Ensure body has at least full viewport height */
  }
  
  navbar{
    background-color:#0D6EFD;
    position: fixed; /* Changed from absolute to fixed for better scrolling behavior */
    right:0;
    left:0;
    height:4em;
    display: flex;
    z-index: 100; /* Add z-index to ensure navbar stays on top */
    box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
    i{
      margin: auto 0 auto 10px;
      padding: 15px;
      transition: transform 1s;
    }
    i:hover{
      background-color:#0D6EFD;
      color:white;
      border-radius: 26px;
      transform: rotate(360deg);
    }
    a{
      margin:auto;
    }
    
  }
  
  /* Main sidebar styles */
  aside {
    overflow-x: hidden;
    width: 0;
    position: fixed; /* Changed from absolute to fixed for better scrolling behavior */
    top: 64px;
    left: 0;
    bottom: 0;
    background-color: #2d2d2d;
    transition: width 0.5s ease-in-out;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 64px); /* Set explicit height calculation based on viewport */
  }
  
  aside ul {
    list-style: none;
    padding: 0;
    margin: 0; /* Reset margin */
    display: flex;
    flex-direction: column;
    height: 100%; /* Make ul take full height */
    width: 100%; /* Ensure full width */
  }
  
  aside li {
    display: flex;
    align-items: center;
    color: #d3d3d3;
    padding: 0;
    font-weight: 400;
    transition: transform 0.3s;
    cursor: pointer;
    height: 48px; /* Standard height for menu items */
    position: relative; /* Added for positioning hover effect */
    width: 100%; /* Ensure full width */
  }

  aside li span {
    white-space: nowrap; /* Prevent text from wrapping */
    overflow: hidden;
    text-overflow: ellipsis; /* Add ellipsis for text that's too long */
  }
  
  aside li:last-child {
    margin-top: auto;
  }
  
  aside li i {
    margin: 0 16px 0 22px; /* Standardize icon margins */
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px; /* Standard width for icon container */
    height: 24px; /* Standard height for icon container */
    font-size: 24px; /* Standard icon size to match ClientSideBar */
  }
  
  /* Center the material icons in the sidebar */
  aside li i.material-icons {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  aside li a, 
  aside li span {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    padding-left: 0;
  }
  
  /* Center the content within the sidebar item */
  aside li {
    justify-content: flex-start; /* Align to start of container but with centered items */
  }

  /* Specific styling for sidebar items */
  .sidebar-item {
    display: flex;
    align-items: center;
  }

  /* For minimized drawer, center the icons and hide text */
  .drawerMin li span {
    display: none; /* Hide text in minimized mode */
  }

  /* For minimized drawer, center the icons */
  .drawerMin li i {
    margin: 0 auto !important; /* Center icons when drawer is minimized with higher specificity */
  }

  /* For minimized drawer, make sure icons are centered */
  .drawerMin .sidebar-item,
  .drawerMin li {
    justify-content: center !important; /* Ensure centering with higher specificity */
    padding-left: 0 !important; /* Remove any left padding */
  }

  /* For open drawer, position icons with text */
  .drawerOpen .sidebar-item {
    justify-content: flex-start;
  }
  
  /* Original hover effect for sidebar items */
  aside li:hover {
    transform: translateX(5px);
    color: #0288d1 !important;
    border-left: 5px solid #0288d1;
    background-color: rgba(2, 136, 209, 0.08); /* Light background on hover */
  }
 
  /* Special handling for minimized drawer hover */
  .drawerMin li:hover {
    transform: translateX(0); /* Don't shift the list item when minimized */
    padding-left: 5px; /* Instead add a bit of padding to account for the left border */
    border-left: 5px solid #0288d1;
    color: #0288d1 !important;
    background-color: rgba(2, 136, 209, 0.08); /* Light background on hover */
  }
  
  .drawerMin {
    width: 70px;
  }
  .drawerOpen {
    width: 240px;
  }
  
  main {
    position: absolute;
    top: 80px;
    padding: 1em;
    margin-left: 0;
    right: 0; /* Added right: 0 to ensure main content spans full width */
    bottom: 0;
    overflow-y: auto;
    transition: margin-left 0.5s ease-in-out;
    min-height: calc(100vh - 80px); /* Ensure main content has at least full height */
  }
  .mainMin {
    margin-left: 70px;
  }
  .mainOpen {
    margin-left: 240px;
  }
  
  /* Tooltip styles for minimized drawer */
  .drawerMin li {
    position: relative;
  }

  /* The tooltip */
  .drawerMin li::after {
    content: attr(data-tooltip);
    position: absolute;
    left: 75px; /* Position to the right of the sidebar */
    top: 50%;
    transform: translateY(-50%);
    background-color: #333;
    color: white;
    padding: 6px 10px;
    border-radius: 4px;
    font-size: 12px;
    white-space: nowrap;
    opacity: 0;
    z-index: 1000;
    pointer-events: none;
    transition: opacity 0.2s;
    border-left: 3px solid #0288d1;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  }

  /* Arrow for tooltip */
  .drawerMin li::before {
    content: "";
    position: absolute;
    left: 70px;
    top: 50%;
    transform: translateY(-50%);
    border-width: 5px;
    border-style: solid;
    border-color: transparent #333 transparent transparent;
    opacity: 0;
    z-index: 1000;
    transition: opacity 0.2s;
  }

  /* Show tooltip and arrow on hover */
  .drawerMin li:hover::after,
  .drawerMin li:hover::before {
    opacity: 1;
  }
  