/* Animations for AUDIT-AI Landing Page */

/* Blob animations */
@keyframes blob {
  0% {
    transform: translate(0, 0) scale(1);
  }
  33% {
    transform: translate(30px, -50px) scale(1.1);
  }
  66% {
    transform: translate(-20px, 20px) scale(0.9);
  }
  100% {
    transform: translate(0, 0) scale(1);
  }
}

.animate-blob {
  animation: blob 7s infinite;
}

.animation-delay-2000 {
  animation-delay: 2s;
}

.animation-delay-4000 {
  animation-delay: 4s;
}

/* Data flow animation particles */
.data-particle {
  opacity: 0.8;
  filter: drop-shadow(0 0 2px rgba(59, 130, 246, 0.5));
}

.animate-data-flow-1 {
  animation: pulse-glow 2s infinite;
}

.animate-data-flow-1-delay {
  animation: pulse-glow 2s infinite;
  animation-delay: 1s;
}

.animate-data-flow-reverse-1 {
  animation: pulse-glow-alt 2s infinite;
  animation-delay: 0.5s;
}

.animate-data-flow-2 {
  animation: pulse-glow 3s infinite;
}

.animate-data-flow-2-delay {
  animation: pulse-glow 3s infinite;
  animation-delay: 1.5s;
}

.animate-data-flow-reverse-2 {
  animation: pulse-glow-alt 3s infinite;
  animation-delay: 0.7s;
}

.animate-data-flow-3 {
  animation: pulse-glow 2.5s infinite;
}

.animate-data-flow-3-delay {
  animation: pulse-glow 2.5s infinite;
  animation-delay: 1.2s;
}

.animate-data-flow-reverse-3 {
  animation: pulse-glow-alt 2.5s infinite;
  animation-delay: 0.3s;
}

.animate-data-flow-4 {
  animation: pulse-glow 3.5s infinite;
}

.animate-data-flow-reverse-4 {
  animation: pulse-glow-alt 3.5s infinite;
  animation-delay: 1.8s;
}

.animate-data-flow-reverse-4-delay {
  animation: pulse-glow-alt 3.5s infinite;
  animation-delay: 0.9s;
}

@keyframes pulse-glow {
  0%, 100% { opacity: 0.8; r: 3; }
  50% { opacity: 1; r: 4; filter: drop-shadow(0 0 4px rgba(59, 130, 246, 0.8)); }
}

@keyframes pulse-glow-alt {
  0%, 100% { opacity: 0.8; r: 3; }
  50% { opacity: 1; r: 4; filter: drop-shadow(0 0 4px rgba(79, 70, 229, 0.8)); }
}

/* Typewriter effect */
.typewriter {
  overflow: hidden;
  border-right: .15em solid transparent;
  white-space: nowrap;
  animation: typing 3.5s steps(40, end), blink-caret .75s step-end infinite;
  animation-delay: 0.5s;
  animation-fill-mode: both;
  width: 100%; /* Ensure text is visible by default */
}

@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: rgba(37, 99, 235, 0.5) }
}

/* Gradient text */
.gradient-text {
  background: linear-gradient(90deg, #1e40af, #3b82f6, #4f46e5);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  background-size: 200% auto;
  animation: textGradient 4s linear infinite;
  color: #3b82f6; /* Fallback */
  font-weight: 700;
  display: inline-block;
}

@keyframes textGradient {
  0% { background-position: 0% center }
  100% { background-position: 200% center }
}

/* Grid animation */
.grid-animation {
  position: absolute;
  width: 200%;
  height: 200%;
  top: -50%;
  left: -50%;
  background-image: 
    linear-gradient(rgba(37, 99, 235, 0.05) 1px, transparent 1px),
    linear-gradient(90deg, rgba(37, 99, 235, 0.05) 1px, transparent 1px);
  background-size: 50px 50px;
  transform: perspective(500px) rotateX(60deg);
  animation: grid 20s linear infinite;
}

@keyframes grid {
  0% { transform: perspective(500px) rotateX(60deg) translateY(0); }
  100% { transform: perspective(500px) rotateX(60deg) translateY(50px); }
}

/* Button animations */
.pulse-animation {
  position: relative;
}

.pulse-animation::after {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 0.375rem;
  box-shadow: 0 0 0 0 rgba(37, 99, 235, 0.7);
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% { box-shadow: 0 0 0 0 rgba(37, 99, 235, 0.7); }
  70% { box-shadow: 0 0 0 10px rgba(37, 99, 235, 0); }
  100% { box-shadow: 0 0 0 0 rgba(37, 99, 235, 0); }
}

.hover-glow:hover {
  box-shadow: 0 0 8px rgba(37, 99, 235, 0.5);
  transition: box-shadow 0.3s ease;
}

/* Floating icons */
.floating-icon {
  position: absolute;
  animation-name: float;
  animation-duration: 6s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

.floating-1 {
  top: 20%;
  left: 10%;
  animation-delay: 0s;
}

.floating-2 {
  top: 50%;
  right: 15%;
  animation-delay: 1s;
}

.floating-3 {
  bottom: 30%;
  left: 20%;
  animation-delay: 2s;
}

@keyframes float {
  0% { transform: translateY(0) rotate(0deg); }
  50% { transform: translateY(-20px) rotate(5deg); }
  100% { transform: translateY(0) rotate(0deg); }
}

/* Fade-in and slide-up animations for sections */
.fade-in-up {
  opacity: 1; /* Make visible by default */
  transform: translateY(0); /* Default position */
  transition: opacity 0.6s ease, transform 0.6s ease;
}

/* Only apply animation when JS is working */
.js .fade-in-up:not(.visible) {
  opacity: 0;
  transform: translateY(20px);
}

.fade-in-up.visible {
  opacity: 1;
  transform: translateY(0);
}

/* AI-themed particle dots */
.particle {
  position: absolute;
  border-radius: 50%;
  background: rgba(59, 130, 246, 0.3);
  animation: particles 8s linear infinite;
}

@keyframes particles {
  0% {
    opacity: 0;
    transform: translateY(0) translateX(0);
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(-100px) translateX(20px);
  }
}

/* Feature card hover effects */
.feature-card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.feature-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

/* Data connection animation */
.data-line {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: dash 3s linear forwards;
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

/* Stagger fade-in animation for children */
.stagger-fade-in > * {
  /* Elements visible by default */
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.4s ease, transform 0.4s ease;
}

/* Only apply animation when JS is working and no-js class is absent */
.js .stagger-fade-in:not(.no-js):not(.visible) > * {
  opacity: 0;
  transform: translateY(20px);
}

/* Make all children visible by default if browser doesn't support JS or for testing */
.stagger-fade-in.no-js > * {
  opacity: 1;
  transform: translateY(0);
}

.stagger-fade-in.visible > *:nth-child(1) {
  opacity: 1;
  transform: translateY(0);
  transition-delay: 0.1s;
}

.stagger-fade-in.visible > *:nth-child(2) {
  opacity: 1;
  transform: translateY(0);
  transition-delay: 0.2s;
}

.stagger-fade-in.visible > *:nth-child(3) {
  opacity: 1;
  transform: translateY(0);
  transition-delay: 0.3s;
}

.stagger-fade-in.visible > *:nth-child(4) {
  opacity: 1;
  transform: translateY(0);
  transition-delay: 0.4s;
}

.stagger-fade-in.visible > *:nth-child(5) {
  opacity: 1;
  transform: translateY(0);
  transition-delay: 0.5s;
}

.stagger-fade-in.visible > *:nth-child(6) {
  opacity: 1;
  transform: translateY(0);
  transition-delay: 0.6s;
}

.stagger-fade-in.visible > *:nth-child(7) {
  opacity: 1;
  transform: translateY(0);
  transition-delay: 0.7s;
}

.stagger-fade-in.visible > *:nth-child(8) {
  opacity: 1;
  transform: translateY(0);
  transition-delay: 0.8s;
}

.stagger-fade-in.visible > *:nth-child(9) {
  opacity: 1;
  transform: translateY(0);
  transition-delay: 0.9s;
}

/* Glowing border animation */
.glow-border {
  position: relative;
}

.glow-border::before {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: inherit;
  padding: 2px;
  background: linear-gradient(90deg, #3b82f6, #6366f1, #8b5cf6, #3b82f6);
  -webkit-mask: 
    linear-gradient(#fff 0 0) content-box, 
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  background-size: 300% 100%;
  animation: animatedBorder 4s infinite linear;
}

@keyframes animatedBorder {
  0% { background-position: 0 0; }
  100% { background-position: 300% 0; }
} 