/* styles.css */
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;  /* Add this to prevent horizontal scroll */
    background-color: white;
    font-family: sans-serif;
}

navbar {
    background-color: #0D6EFD;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 4em;
    display: flex;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    z-index: 1000; /* Ensure the navbar is above everything else */
}

navbar i {
    margin: auto 0 auto 10px;
    padding: 15px;
    transition: transform 1s;
}

navbar i:hover {
    background-color: #0D6EFD;
    color: white;
    border-radius: 26px;
    transform: rotate(360deg);
}

navbar a {
    margin: auto;
}

aside {
    position: fixed;
    top: 4em; /* Align with the navbar height */
    left: 0;
    bottom: 0;
    width: 70px; /* Default width */
    background-color: #2d2d2d;
    color: white;
    transition: width 0.5s ease-in-out;
    display: flex;
    flex-direction: column;
    z-index: 999; /* Sidebar should be below navbar */
}

.drawerMin {
    width: 70px;
}

.drawerOpen {
    width: 240px;
}

aside ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
}

aside li {
    display: flex;
    align-items: center;
    color: #d3d3d3;
    padding: 12px 0 12px 22px;
    font-weight: 400;
    transition: transform 1s;
    cursor: pointer;
}

aside li:last-child {
    margin-top: auto;
}

aside li i {
    margin-right: 1em;
}

aside li:hover {
    transform: translateX(5px);
    color: #0288d1 !important;
    border-left: 5px solid #0288d1;
}

main {
  position: relative;
  top: 4em;
  left: 0;
  padding: 1em;
  margin-left: 70px;  /* Default margin when the sidebar is minimized */
  transition: margin-left 0.5s ease-in-out;
  background-color: white;
  height: calc(100vh - 4em);
  overflow-y: auto;
}

.mainMin {
    margin-left: 60px;
}

.mainOpen {
  margin-left: 240px;  /* Ensure this is not causing overflow */
}

/* Optional: Style the logout button and other sidebar items if necessary */
