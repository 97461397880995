/* styles.css */
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');


body {
    margin: 0;
    padding: 0;
    background-color: white;
    font-family: sans-serif;
    min-height: 100vh; /* Ensure body has at least full viewport height */
  }
  
  navbar{
    background-color:#0D6EFD;
    position: fixed; /* Changed from absolute to fixed for better scrolling behavior */
    right:0;
    left:0;
    height:4em;
    display: flex;
    z-index: 100; /* Add z-index to ensure navbar stays on top */
    box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
    i{
      margin: auto 0 auto 10px;
      padding: 15px;
      transition: transform 1s;
    }
    i:hover{
      background-color:#0D6EFD;
      color:white;
      border-radius: 26px;
      transform: rotate(360deg);
    }
    a{
      margin:auto;
    }
    
  }
  
  aside {
    overflow-x: hidden;
    width: 0;
    position: fixed; /* Changed from absolute to fixed for better scrolling behavior */
    top: 64px;
    left: 0;
    bottom: 0;
    background-color: #2d2d2d;
    transition: width 0.5s ease-in-out;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 64px); /* Set explicit height calculation based on viewport */
  }
  
  aside ul {
    list-style: none;
    padding: 0;
    margin: 0; /* Reset margin */
    display: flex;
    flex-direction: column;
    height: 100%; /* Make ul take full height */
  }
  
  aside li {
    display: flex;
    align-items: center;
    color: #d3d3d3;
    padding: 12px 0 12px 22px;
    font-weight: 400;
    transition: transform 1s;
    cursor: pointer;
  }
  
  aside li:last-child {
    margin-top: auto;
  }
  
  aside li i {
    margin-right: 1em;
  }
  
  
  aside li:hover {
    transform: translateX(5px);
    color: #0288d1 !important;
    border-left: 5px solid #0288d1;
  }
  .drawerMin {
    width: 70px;
  }
  .drawerOpen {
    width: 240px;
  }
  
  main {
    position: absolute;
    top: 80px;
    padding: 1em;
    margin-left: 0;
    right: 0; /* Added right: 0 to ensure main content spans full width */
    bottom: 0;
    overflow-y: auto;
    transition: margin-left 0.5s ease-in-out;
    min-height: calc(100vh - 80px); /* Ensure main content has at least full height */
  }
  .mainMin {
    margin-left: 70px;
  }
  .mainOpen {
    margin-left: 240px;
  }

  /* Main Select Wrapper */
select {
  appearance: none; /* Remove default dropdown style */
  -webkit-appearance: none; /* Safari */
  -moz-appearance: none; /* Firefox */
  width: 100%;
  padding: 8px 12px;
  font-size: 12px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  border: 1px solid #ccc;
  border-radius: 6px;
  background-color: #fff;
  color: #333;
  cursor: pointer;
  transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

/* Focused state */
select:focus {
  border-color: #5c6bc0; /* Blue border when focused */
  box-shadow: 0 0 0 3px rgba(92, 107, 192, 0.2); /* Light blue shadow */
  outline: none;
}

/* Custom arrow icon */
select::-ms-expand {
  display: none; /* Hide the default dropdown arrow in IE */
}

/* Dropdown icon (custom arrow) */
select::after {
  content: ' ▼';
  font-size: 12px;
  margin-left: 5px;
  color: #888;
}

/* Style the dropdown options */
option {
  background-color: #fff;
  color: #333;
  padding: 8px;
}

/* Option hover effect */
select option:hover {
  background-color: #f1f1f1;
}

/* Disabled state */
select:disabled {
  background-color: #f9f9f9;
  color: #aaa;
  cursor: not-allowed;
  border-color: #e0e0e0;
}

/* Add some space for the select wrapper if necessary */
select + option {
  margin-top: 5px;
}
  